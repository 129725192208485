import * as React from "react"
import Form, { FormProps } from "../../../components/form"

interface SubmitAStormFormProps extends Omit<FormProps, "children"> {}

export default function SubmitAStormForm(props: SubmitAStormFormProps) {
  return (
    <Form {...props}>
      {inputClasses => (
        <>
          <input
            name="name"
            type="text"
            placeholder="NAME"
            className={inputClasses}
            required
          />

          <input
            name="email"
            type="email"
            placeholder="EMAIL"
            className={inputClasses}
            required
          />

          <input
            name="storeName"
            type="text"
            placeholder="STORE NAME"
            className={inputClasses}
            required
          />

          <input
            name="googleLink"
            type="text"
            placeholder="GOOGLE MAPS LINK"
            className={inputClasses}
          />

          <textarea
            name="address"
            rows={5}
            className={inputClasses}
            placeholder="ADDRESS"
          />

          <textarea
            name="additionalInformation"
            rows={7}
            className={inputClasses}
            placeholder="ADDITIONAL INFORMATION"
          />
        </>
      )}
    </Form>
  )
}
