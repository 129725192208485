import * as React from "react"
import useSiteMetadata from "../hooks/use-site-metadata"
import Layout from "../components/layout"
import Container from "../components/container"
import Hero from "../components/hero"
import SubmitAStormForm from "../partials/page/submit-a-store-form"

const SubmitAStorePage = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <Layout
      title={"Submit a Store"}
      description={
        "Know of a good store that isn't featured on Tokyo Chuko? Please provide us as much information as you can about the store in the form below, and we'll look into it as soon as possible."
      }
      noGap
      className={"bg-white"}
    >
      <Hero title={"Submit a Store"} titleColor={"text-gray-800"} />
      <div className={"flex-grow"}>
        <Container className={"py-10 justify-center"}>
          <div className={"flex flex-col gap-8"}>
            <div className={"prose"}>
              <p>
                Know of a good store that {"isn't"} featured on Tokyo Chuko?
                Please provide us as much information as you can about the store
                in the form below, and {"we'll"} look into it as soon as
                possible.
              </p>
            </div>

            <SubmitAStormForm action={siteMetadata.submitStoreFormAction} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default SubmitAStorePage
